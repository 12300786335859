import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from '../content/Hero';
import Meta from '../../shared/Meta';
import CostEstimator from '../content/CostEstimator';
import Triple from '../content/Triple';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CtaImage from '../content/CtaImage';
import LogoList from '../content/LogoList';
import AnyEvent from '../content/AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class BbqCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`BBQ Food ${cityConfig.vehicle_type} Catering - Weddings, Parties & Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`These BBQ food ${vehicleType}s are born and braised in ${cityConfig.city_name}! Offering a selection of the finest smoked meats, from brisket to ribs. Compare quotes with the best food ${vehicleType}s on our free no-obligation request form to book one or multiple ${vehicleType}s for that special day.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "BBQ Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/BBQ-HEADER.jpeg">
          <h1>BBQ Food {cityConfig.vehicle_type} Catering</h1>
          <p>These BBQ food {vehicleType}s are born and braised in {cityConfig.city_name}! Offering a selection of the finest smoked meats, from brisket to ribs. Compare quotes with the best food {vehicleType}s on our free no-obligation request form to book one or multiple {vehicleType}s for that special day.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="NewSection">
          <h3>How it works</h3>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering1.png"
              title="1. Answer a few questions"
              text={`Gain access to over 500 food ${vehicleType}s by telling us more about your upcoming catering event and food ${vehicleType} needs via our free online request form.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering2.png"
              title="2. Receive quotes"
              text={`We’ll send you quotes to compare food ${vehicleType}s that are available. You can chat with them directly to find out more about their menu and finalize details for your event.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering3.png"
              title={`3. Book your ${vehicleType}`}
              text={`Choose your favorite food ${vehicleType} and book directly with the vendor. We add no added commission of services fees, so you'll always know you get the best deal.`}
            />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/BBQ-1.jpeg" imgClass="img-bbq1"/>
              <div className={styles.Cta_copy}>
                <h4>The best BBQ food {vehicleType}s in {cityConfig.city_name}</h4>
                <p>
                  Nothing beats the smell of delicious BBQ! Your guests will have their mouths watering as the delicious aroma fills the air of your next event. <Link to="/food-trucks/wood-shop-bbq">Woodshop BBQ</Link> is an award-winning food {vehicleType} serving quality Kansas City style BBQ buffet style or on their mobile kitchen on wheels. Try their delicious jalapeno mac and cheese and you'll be wondering where they've been parked your whole life! <Link to="/food-trucks/pecos-pit-bar-b-que">Peco's Pit</Link> BBQ is one of the most famous names in the BBQ game and has multiple restaurants in Washington. You can book their {vehicleType} for your event through {cityConfig.site_title} and have them serve up their delicious bbq pork sandwiches from their streamlined mobile food {vehicleType}.
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/BBQ_-_IMAGE2.jpeg" imgClass="img-bbq2"/>
              <div className={styles.Cta_copy}>
                <h4>Fall off the bone tender</h4>
                <p>
                  All of our BBQ food {vehicleType}s use the highest quality meats and cook with love, sweat, and tears using signature recipes from all over the country. Like <Link to="/food-trucks/hp-s-smokehouse-bbq">HP's Smokehouse BBQ</Link> food {vehicleType}, a father and daughter team who are known for their wood-smoked BBQ and down-home sides. <Link to="/food-trucks/smokestack-lightning-bbq">Smokestack Lightning BBQ</Link> food {vehicleType} specializes in a wide variety of smoked and fire-roasted foods where everything from their bread to their condiments are made from scratch! Whether it's service right off the {vehicleType}, or a custom indoor buffet, our food {vehicleType}s can help make any event a memorable one!
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'bbq' }} />

        <CostEstimator title="BBQ Catering Cost Estimator"/>

        <section className="NewSection">
          <LogoList title="Some of the companies we serve" />
        </section>

        <AnyEvent/>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Think of us for your next event</h3>
            <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
            <a
              className="Button"
              href="/catering/register"
            >
              Food {cityConfig.vehicle_type} Catering
            </a>
          </div>
        </section>
      </div>
    );
  }
}

BbqCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BbqCatering);
